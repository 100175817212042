import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values, 
      } = formRenderProps;


      const notSelected = !values?.commercialEquipment?.length

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || notSelected;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('commercialEquipment', filterConfig);

      const ovensEquipmentOptions = config.custom.ovensEquipmentOptions;
      const rangesAndVentilationEquipmentOptions = config.custom.rangesAndVentilationEquipmentOptions;
      const foodProcessorsEquipmentOptions = config.custom.foodProcessorsEquipmentOptions;
      const mixersEquipmentOptions = config.custom.mixersEquipmentOptions;
      const equipmentOptions1 = config.custom.equipmentOptions1;
      const sinksEquipmentOptions = config.custom.sinksEquipmentOptions;
      const equipmentOptions2 = config.custom.equipmentOptions2;


      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.featuresRow}>
            <div className={css.featuresCol}>
              <FieldCheckboxGroup className={css.features} id='ovensEquipmentOptions' name={name} options={ovensEquipmentOptions} label={"Ovens"} />
              <FieldCheckboxGroup className={css.features} id='rangesAndVentilationEquipmentOptions' name={name} options={rangesAndVentilationEquipmentOptions} label={"Ranges and ventilation"} />
              <FieldCheckboxGroup className={css.features} id='foodProcessorsEquipmentOptions' name={name} options={foodProcessorsEquipmentOptions} label={"Food processors"} />
              <FieldCheckboxGroup className={css.features} id='mixersEquipmentOptions' name={name} options={mixersEquipmentOptions} label={"Mixers"} />
              <FieldCheckboxGroup className={css.features} id='sinksEquipmentOptions' name={name} options={sinksEquipmentOptions} label={"Sinks"} />
            </div>
            <div className={css.featuresCol}>
              <FieldCheckboxGroup className={css.features} id='equipmentOptions1' name={name} options={equipmentOptions1} />
              <FieldCheckboxGroup className={css.features} id='equipmentOptions2' name={name} options={equipmentOptions2} />
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
