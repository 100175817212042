import React from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterCustomPlain from './SelectSingleFilterCustomPlain';

const SelectSingleFilterCustom = props => {
  const { showAsPopup, ...rest } = props;
  return <SelectSingleFilterCustomPlain {...rest} />
};

SelectSingleFilterCustom.defaultProps = {
  showAsPopup: false,
};

SelectSingleFilterCustom.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleFilterCustom;
