/**
 * Filestack-react is having a bug on container id
 * this component here is for making it work
 */
import React, { useEffect } from 'react';
import { client as filestack } from 'filestack-react';

import { IconClose, IconCheckmark } from '../../components';
import { Buffer } from 'buffer';
import sha256, { Hash, HMAC } from 'fast-sha256'

import css from './FilePicker.module.css';
import classNames from 'classnames';

const usePicker = (_ref) => {
  const { apikey } = _ref;
  const _ref$pickerOptions = _ref.pickerOptions;
  const pickerOptions = _ref$pickerOptions === undefined ? {} : _ref$pickerOptions;
  const _ref$clientOptions = _ref.clientOptions;
  const clientOptions = _ref$clientOptions === undefined ? {} : _ref$clientOptions;
  const _ref$onUploadDone = _ref.onUploadDone;
  // eslint-disable-next-line no-console
  const onUploadDone = _ref$onUploadDone === undefined ? console.log : _ref$onUploadDone;
  const _ref$onError = _ref.onError;
  // eslint-disable-next-line no-console
  const onError = _ref$onError === undefined ? console.error : _ref$onError;

  const _onError = (error) => {
    onError(error);
  };
  const _onUploadDone = (result) => {
    onUploadDone(result);
  };
  const rootId = 'asset-root';
  const containerId = _ref.container;

  // Expire the file in 1 hour
  const policy = {
    expiry: Math.round(Date.now() / 1000) + 3600,
    call: ['pick'],
  }

  const base64Policy = new Buffer(JSON.stringify(policy)).toString('base64');

  const hmac = new HMAC(Buffer.from(process.env.REACT_APP_FILESTACK_SECRET_KEY)) // also Hash and HMAC classes
  const mac = hmac.update(Buffer.from(base64Policy)).digest()
  const buf = Buffer.from(Array.from(mac)).toString('hex')  

  const security = {
    policy: base64Policy,
    signature: buf
  }

  const picker = filestack.Filestack(apikey, {...clientOptions, security: security}).picker({
    ...pickerOptions,
    rootId,
    container: `#${containerId}`,
    onUploadDone: _onUploadDone
  });

  useEffect(() => {
    picker.open().then().catch(_onError);
    return () => {
      if (picker) {
        picker.close();
      }
    };
  }, []);
  return {
    containerId
  };
};

const FilestackPicker = (_ref) => {
  const {
    apikey,
    pickerOptions,
    clientOptions,
    onUploadDone,
    onError,
    customText,
    className,
    id,
    label,
    currentFile,
    onDeleteFiles,
    ableToDownload
  } = _ref;

  const _usePicker = usePicker({
    container: id,
    apikey,
    pickerOptions: {
      ...pickerOptions,
      displayMode: filestack.PickerDisplayMode.dropPane,
      dropPane: {
        customText: customText || 'Drop files here',
        overlay: false,
      },
    },
    clientOptions,
    onUploadDone,
    onError
  });

  return (
    <div className={className} data-testid="picker-inline">
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div id={id} style={{ marginTop: "12px" }} className={currentFile && currentFile !== {} && currentFile.file !== null ? css.disabled : ""} />
      <div style={{ marginTop: "12px" }} className={classNames(css.currentFile, {
        [css.disabled]: !currentFile || (currentFile === {} || currentFile.file === null)
      })}>
        <IconCheckmark />
        <span>{currentFile?.filename}</span>
      </div>
      {ableToDownload && currentFile && currentFile !== {} && currentFile.file !== null ? (
        <div className={css.actions}>
          <a href={currentFile.file} target="_blank">View current {label}</a>
          <div
            className={css.delete}
            onClick={() => onDeleteFiles()}
          >
            <IconClose title={"Delete"} />
          </div>
        </div>
      ) : null}
    </div>
  )
};

export default FilestackPicker;