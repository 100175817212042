import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="144.698"
        height="40"
        viewBox="0 0 184.698 67.699"
      >
        <g id="Group_19" transform="translate(0.006)">
          <path
            id="Path_16"
            d="M101.53,46.942A16.37,16.37,0,1,1,73.565,35.365a16.616,16.616,0,0,1,23.227,0,15.746,15.746,0,0,1,4.744,11.577m-8.261,0a8.139,8.139,0,0,0-8.076-8.05,7.894,7.894,0,0,0-5.75,2.346,7.7,7.7,0,0,0-2.387,5.7,8.11,8.11,0,1,0,16.219.128v-.128"
            transform="translate(-33.475 -14.905)"
            fill="#ffce00"
          />
          <path
            id="Path_17"
            d="M171.987,48.41h-8.261V32.966a8.161,8.161,0,0,0-8.112-8.061,7.936,7.936,0,0,0-5.776,2.351,7.7,7.7,0,0,0-2.393,5.709V48.41H139.18V0h8.266V18.729a16.647,16.647,0,0,1,8.138-2.079,15.9,15.9,0,0,1,11.629,4.734,15.663,15.663,0,0,1,4.775,11.577V48.415Z"
            transform="translate(-67.726)"
            fill="#ffce00"
          />
          <path
            id="Path_18"
            d="M240.9,46.939a16.371,16.371,0,0,1-32.74.262v-.262a15.671,15.671,0,0,1,4.775-11.577,16.606,16.606,0,0,1,23.227,0A15.746,15.746,0,0,1,240.9,46.939m-8.261,0a8.139,8.139,0,0,0-8.076-8.05,7.894,7.894,0,0,0-5.75,2.346,7.7,7.7,0,0,0-2.387,5.7,8.11,8.11,0,1,0,16.219.128v-.128"
            transform="translate(-101.288 -14.902)"
            fill="#ffce00"
          />
          <path
            id="Path_19"
            d="M299.826,53.723a8.234,8.234,0,0,1-1.961,5.339q-3.92,4.782-11.141,4.6a19.891,19.891,0,0,1-6.274-1.284,17.642,17.642,0,0,1-5.54-3.06l4.59-5.935a10.146,10.146,0,0,0,7.039,3.122h.185a6.674,6.674,0,0,0,2.752-.549,2.286,2.286,0,0,0,1.592-2.079v-.246a2.5,2.5,0,0,0-1.9-2.018c-.452-.082-1.407-.267-2.875-.549a24.47,24.47,0,0,1-4.651-1.345,8.463,8.463,0,0,1-5.571-8.384q0-5.753,5.694-8.63a12.34,12.34,0,0,1,5.447-1.345,18.922,18.922,0,0,1,6.366,1.042,11.019,11.019,0,0,1,5.386,3.368l-5.509,4.96a6.412,6.412,0,0,0-4.652-2.2q-3.92,0-3.917,2.577v.123c0,.816,1.063,1.515,3.183,2.084q.246.062,4.041.8,7.709,1.471,7.711,9.447v.18Z"
            transform="translate(-133.771 -15.259)"
            fill="#ffce00"
          />
          <path
            id="Path_20"
            d="M344.191,48.41a15.88,15.88,0,0,1-11.629-4.734,15.682,15.682,0,0,1-4.775-11.583V0h8.261V16.645h8.138v7.039h-8.138v8.384a7.742,7.742,0,0,0,2.387,5.725,7.883,7.883,0,0,0,5.75,2.357V48.41Z"
            transform="translate(-159.498)"
            fill="#ffce00"
          />
          <path
            id="Path_21"
            d="M32.737,46.943a15.835,15.835,0,0,1-4.744,11.608,15.735,15.735,0,0,1-11.6,4.764,15.869,15.869,0,0,1-11.66-4.734,16.535,16.535,0,0,1,.031-23.211A15.88,15.88,0,0,1,16.4,30.637a15.811,15.811,0,0,1,11.6,4.734,15.746,15.746,0,0,1,4.744,11.577m-8.261,0a7.715,7.715,0,0,0-2.387-5.668A7.791,7.791,0,0,0,16.4,38.9a7.894,7.894,0,0,0-5.75,2.346,7.7,7.7,0,0,0-2.387,5.7,7.793,7.793,0,0,0,2.387,5.73,7.846,7.846,0,0,0,5.75,2.377,8.081,8.081,0,0,0,8.081-8.107"
            transform="translate(0 -14.906)"
            fill="#ffce00"
          />
          <path
            id="Path_22"
            d="M24.815,100.48a8.061,8.061,0,0,1-16.116,0H.52a16.24,16.24,0,0,0,32.479,0H24.82Z"
            transform="translate(-0.256 -48.892)"
            fill="#ffce00"
          />
          <path
            id="Path_23"
            d="M232.715,115.54a8.061,8.061,0,0,0-16.116,0H208.42a16.24,16.24,0,0,1,32.479,0H232.72Z"
            transform="translate(-101.418 -48.381)"
            fill="#ffce00"
          />
        </g>
      </svg>
    );
  } else if (format === 'shared_space') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="354.323"
        height="90.877"
        viewBox="0 0 354.323 90.877"
      >
        <g id="Group_58" data-name="Group 58" transform="translate(0 1)">
          <g
            id="Group_57"
            data-name="Group 57"
            transform="translate(0 -1)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_145"
              data-name="Path 145"
              d="M275.04,124.811a21.717,21.717,0,1,1-6.366-15.536,21.252,21.252,0,0,1,6.366,15.536m-11.089,0a10.919,10.919,0,0,0-10.842-10.8,10.615,10.615,0,0,0-7.721,3.151,10.326,10.326,0,0,0-3.2,7.65,10.883,10.883,0,1,0,21.766,0"
              transform="translate(-183.699 -81.812)"
              fill="#ffce00"
            />
            <path
              id="Path_146"
              data-name="Path 146"
              d="M511.631,64.97H500.542V44.243a10.37,10.37,0,0,0-3.216-7.618,10.5,10.5,0,0,0-7.667-3.2,10.667,10.667,0,0,0-7.75,3.154,10.329,10.329,0,0,0-3.215,7.659V64.97H467.605V0h11.089V25.134a22.37,22.37,0,0,1,10.924-2.792A21.333,21.333,0,0,1,505.224,28.7a21.041,21.041,0,0,1,6.407,15.534Z"
              transform="translate(-371.7)"
              fill="#ffce00"
            />
            <path
              id="Path_147"
              data-name="Path 147"
              d="M743.25,124.811a21.972,21.972,0,1,1-43.943,0,21.04,21.04,0,0,1,6.406-15.536,22.291,22.291,0,0,1,31.171,0,21.114,21.114,0,0,1,6.365,15.536m-11.088,0a10.919,10.919,0,0,0-10.842-10.8,10.614,10.614,0,0,0-7.721,3.151,10.327,10.327,0,0,0-3.2,7.65,10.883,10.883,0,1,0,21.766,0"
              transform="translate(-555.88 -81.812)"
              fill="#ffce00"
            />
            <path
              id="Path_148"
              data-name="Path 148"
              d="M957.046,135.295a11.031,11.031,0,0,1-2.628,7.163q-5.258,6.422-14.949,6.176a26.7,26.7,0,0,1-8.42-1.725,23.6,23.6,0,0,1-7.433-4.107l6.16-7.968q4.435,4.189,9.445,4.189h.247a8.916,8.916,0,0,0,3.7-.739,3.07,3.07,0,0,0,2.136-2.792v-.329a3.367,3.367,0,0,0-2.547-2.71q-.9-.164-3.861-.739a33.065,33.065,0,0,1-6.242-1.807q-7.475-3.2-7.474-11.253,0-7.72,7.639-11.581a16.578,16.578,0,0,1,7.31-1.807,25.367,25.367,0,0,1,8.542,1.4q5.092,1.725,7.228,4.517l-7.392,6.654a8.61,8.61,0,0,0-6.242-2.957q-5.258,0-5.257,3.459v.165q0,1.648,4.271,2.8.328.083,5.422,1.07,10.349,1.977,10.349,12.68Z"
              transform="translate(-734.183 -83.672)"
              fill="#ffce00"
            />
            <path
              id="Path_149"
              data-name="Path 149"
              d="M1123.245,64.97a21.322,21.322,0,0,1-15.606-6.357,21.061,21.061,0,0,1-6.406-15.546V0h11.088V22.341h10.924v9.446h-10.924V43.04a10.388,10.388,0,0,0,3.2,7.68,10.591,10.591,0,0,0,7.721,3.162Z"
              transform="translate(-875.37)"
              fill="#ffce00"
            />
            <path
              id="Path_150"
              data-name="Path 150"
              d="M43.943,124.811a21.252,21.252,0,0,1-6.365,15.578,21.123,21.123,0,0,1-15.565,6.394,21.288,21.288,0,0,1-15.647-6.353,22.191,22.191,0,0,1,.041-31.154,21.33,21.33,0,0,1,15.606-6.354,21.2,21.2,0,0,1,15.565,6.354,21.114,21.114,0,0,1,6.365,15.536m-11.088,0a10.368,10.368,0,0,0-3.2-7.609,10.447,10.447,0,0,0-7.639-3.192,10.614,10.614,0,0,0-7.721,3.151,10.326,10.326,0,0,0-3.2,7.65,10.463,10.463,0,0,0,3.2,7.692,10.538,10.538,0,0,0,7.721,3.191,10.407,10.407,0,0,0,7.68-3.191,10.537,10.537,0,0,0,3.162-7.692"
              transform="translate(0 -81.812)"
              fill="#ffce00"
            />
            <path
              id="Path_151"
              data-name="Path 151"
              d="M34.354,337.58a10.815,10.815,0,0,1-21.628,0H1.748a21.793,21.793,0,0,0,43.584,0Z"
              transform="translate(-1.389 -268.343)"
              fill="#ffce00"
            />
            <path
              id="Path_152"
              data-name="Path 152"
              d="M732.786,355.578a10.815,10.815,0,0,0-21.628,0H700.18a21.793,21.793,0,0,1,43.584,0Z"
              transform="translate(-556.574 -265.448)"
              fill="#ffce00"
            />
            <path
              id="Path_153"
              data-name="Path 153"
              d="M1295.11,147.341c-3.79,0-6.333-2.111-6.536-4.858h4.3a1.992,1.992,0,0,0,2.188,1.653c1.119,0,1.7-.509,1.7-1.144,0-2.289-7.732-.636-7.732-5.85,0-2.416,2.06-4.4,5.774-4.4,3.662,0,5.7,2.035,5.977,4.833h-4.019a1.871,1.871,0,0,0-2.085-1.6c-1.018,0-1.577.407-1.577,1.094,0,2.264,7.681.661,7.757,5.952,0,2.467-2.188,4.324-5.748,4.324"
              transform="translate(-1024.288 -105.517)"
              fill="#ffce00"
            />
            <path
              id="Path_154"
              data-name="Path 154"
              d="M1361.491,111.164h4.349V117.7a5.234,5.234,0,0,1,4.375-2.06c3.23,0,5.392,2.238,5.392,6.053v8.292h-4.324v-7.706c0-1.933-1.068-3-2.721-3s-2.722,1.068-2.722,3v7.706h-4.349Z"
              transform="translate(-1082.25 -88.364)"
              fill="#ffce00"
            />
            <path
              id="Path_155"
              data-name="Path 155"
              d="M1447.116,132.742a4.94,4.94,0,0,1,4.375,2.213v-2.009h4.349v14.192h-4.349v-2.009a5.019,5.019,0,0,1-4.4,2.213c-3.459,0-6.231-2.849-6.231-7.325s2.772-7.274,6.257-7.274m1.271,3.79a3.16,3.16,0,0,0-3.1,3.484c0,2.264,1.475,3.536,3.1,3.536,1.653,0,3.1-1.246,3.1-3.51s-1.45-3.51-3.1-3.51"
              transform="translate(-1145.34 -105.517)"
              fill="#ffce00"
            />
            <path
              id="Path_156"
              data-name="Path 156"
              d="M1533.625,147.336h-4.349V133.144h4.349v2.365a5.291,5.291,0,0,1,4.451-2.518v4.6h-1.2c-2.06,0-3.255.712-3.255,3.154Z"
              transform="translate(-1215.623 -105.715)"
              fill="#ffce00"
            />
            <path
              id="Path_157"
              data-name="Path 157"
              d="M1585.309,147.341c-4.171,0-7.172-2.8-7.172-7.3s2.95-7.3,7.172-7.3a6.688,6.688,0,0,1,7.071,7.071,10.986,10.986,0,0,1-.076,1.272h-9.843a2.631,2.631,0,0,0,2.7,2.721,2.313,2.313,0,0,0,2.264-1.373h4.629a6.633,6.633,0,0,1-6.74,4.909m-2.823-8.673h5.443a2.454,2.454,0,0,0-2.671-2.441,2.69,2.69,0,0,0-2.772,2.441"
              transform="translate(-1254.461 -105.517)"
              fill="#ffce00"
            />
            <path
              id="Path_158"
              data-name="Path 158"
              d="M1660.784,115.59a5.209,5.209,0,0,1,4.375,2.162v-6.587h4.349v18.821h-4.349v-2.035a4.909,4.909,0,0,1-4.375,2.238c-3.484,0-6.257-2.849-6.257-7.325s2.772-7.274,6.257-7.274m1.271,3.79a3.16,3.16,0,0,0-3.1,3.484c0,2.264,1.475,3.536,3.1,3.536,1.654,0,3.1-1.246,3.1-3.51s-1.449-3.51-3.1-3.51"
              transform="translate(-1315.185 -88.365)"
              fill="#ffce00"
            />
            <path
              id="Path_159"
              data-name="Path 159"
              d="M1295.11,260.051c-3.79,0-6.333-2.111-6.536-4.858h4.3a1.992,1.992,0,0,0,2.188,1.653c1.119,0,1.7-.509,1.7-1.144,0-2.289-7.732-.636-7.732-5.85,0-2.416,2.06-4.4,5.774-4.4,3.662,0,5.7,2.035,5.977,4.833h-4.019a1.871,1.871,0,0,0-2.085-1.6c-1.018,0-1.577.407-1.577,1.094,0,2.264,7.681.661,7.757,5.952,0,2.467-2.188,4.324-5.748,4.324"
              transform="translate(-1024.288 -195.11)"
              fill="#ffce00"
            />
            <path
              id="Path_160"
              data-name="Path 160"
              d="M1370.215,245.452c3.485,0,6.257,2.8,6.257,7.274s-2.772,7.325-6.257,7.325a5.106,5.106,0,0,1-4.375-2.187v8.749h-4.349V245.656h4.349v2.009a5,5,0,0,1,4.375-2.213m-1.3,3.79a3.533,3.533,0,1,0,3.129,3.484,3.183,3.183,0,0,0-3.129-3.484"
              transform="translate(-1082.25 -195.11)"
              fill="#ffce00"
            />
            <path
              id="Path_161"
              data-name="Path 161"
              d="M1447.734,245.452a4.94,4.94,0,0,1,4.375,2.213v-2.009h4.349v14.192h-4.349v-2.009a5.019,5.019,0,0,1-4.4,2.213c-3.459,0-6.231-2.849-6.231-7.325s2.772-7.274,6.257-7.274m1.272,3.79a3.16,3.16,0,0,0-3.1,3.484c0,2.264,1.475,3.536,3.1,3.536,1.653,0,3.1-1.246,3.1-3.51s-1.45-3.51-3.1-3.51"
              transform="translate(-1145.831 -195.11)"
              fill="#ffce00"
            />
            <path
              id="Path_162"
              data-name="Path 162"
              d="M1532.8,245.452a6.5,6.5,0,0,1,6.816,5.341h-4.629a2.242,2.242,0,0,0-2.264-1.628c-1.551,0-2.619,1.221-2.619,3.586s1.068,3.586,2.619,3.586a2.208,2.208,0,0,0,2.264-1.628h4.629a6.591,6.591,0,0,1-6.816,5.341c-4.171,0-7.122-2.8-7.122-7.3,0-4.476,2.95-7.3,7.122-7.3"
              transform="translate(-1212.762 -195.11)"
              fill="#ffce00"
            />
            <path
              id="Path_163"
              data-name="Path 163"
              d="M1607.877,260.051c-4.171,0-7.172-2.8-7.172-7.3s2.95-7.3,7.172-7.3a6.688,6.688,0,0,1,7.07,7.071,11.066,11.066,0,0,1-.076,1.272h-9.843a2.631,2.631,0,0,0,2.7,2.721,2.313,2.313,0,0,0,2.263-1.373h4.629a6.633,6.633,0,0,1-6.74,4.909m-2.823-8.673h5.443a2.454,2.454,0,0,0-2.671-2.441,2.69,2.69,0,0,0-2.772,2.441"
              transform="translate(-1272.401 -195.11)"
              fill="#ffce00"
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      {...rest}
      width="28"
      height="28"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
    >
      <circle className="cls-1" cx="128" cy="128" r="128" fill="#ffce00" />
      <g>
        <path
          className="cls-2"
          fill="#1a1a1a"
          d="M215.33,103.05c0,11.31-3.9,20.92-11.83,28.85s-17.54,11.83-28.85,11.83-21.18-3.9-28.98-11.83c-7.93-7.8-11.83-17.54-11.83-28.98s3.9-20.92,11.83-28.85c7.93-7.8,17.54-11.83,28.98-11.83s20.92,3.9,28.85,11.83c7.93,7.93,11.83,17.54,11.83,28.98m-20.53,0c0-5.46-1.95-10.14-5.98-14.16-3.9-3.9-8.71-5.98-14.16-5.98s-10.4,1.95-14.29,5.85-5.98,8.58-5.98,14.16,1.95,10.27,5.98,14.29,8.71,5.98,14.29,5.98,10.27-1.95,14.29-5.98c3.9-3.9,5.85-8.71,5.85-14.16"
        />
        <path
          className="cls-2"
          fill="#1a1a1a"
          d="M122.15,103.05c0,11.31-3.9,20.92-11.83,28.85s-17.54,11.83-28.85,11.83-21.18-3.9-28.98-11.83c-7.93-7.8-11.83-17.54-11.83-28.98s3.9-20.92,11.83-28.85c7.93-7.8,17.54-11.83,28.98-11.83s20.92,3.9,28.85,11.83c7.8,7.93,11.83,17.54,11.83,28.98m-20.53,0c0-5.46-1.95-10.14-5.98-14.16s-8.71-5.98-14.16-5.98-10.4,1.95-14.29,5.85-5.98,8.58-5.98,14.16,1.95,10.27,5.98,14.29c3.9,3.9,8.71,5.98,14.29,5.98s10.27-1.95,14.29-5.98c3.77-3.9,5.85-8.71,5.85-14.16"
        />
        <path
          className="cls-2"
          fill="#1a1a1a"
          d="M101.49,153.47c-.13,10.92-9.1,19.75-20.01,19.75s-19.88-8.84-20.01-19.75h-20.53c.13,22.09,18.19,40.15,40.41,40.15s40.28-17.93,40.41-40.15h-20.27Z"
        />
        <path
          className="cls-2"
          fill="#1a1a1a"
          d="M194.66,191.16c-.13-10.92-9.1-19.75-20.01-19.75s-19.88,8.84-20.01,19.75h-20.4c.13-22.09,18.19-40.15,40.41-40.15s40.28,17.93,40.41,40.15h-20.4Z"
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
