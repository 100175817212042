/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
import {
  KITCHEN_TYPE,
  DINING_SPACE_TYPE,
} from './util/types';

export const listingTypeOptions = [
  { key: KITCHEN_TYPE, label: 'Kitchen' },
  { key: DINING_SPACE_TYPE, label: 'Dining space' },
]

export const ovensEquipmentOptions = [
  { key: 'convectionOvens', label: 'Convection ovens' },
  { key: 'combinationOvens', label: 'Combination ovens' },
  { key: 'pizzaOvens', label: 'Pizza ovens' },
  { key: 'conveyorOvens', label: 'Conveyor ovens' },
]

export const rangesAndVentilationEquipmentOptions = [
  { key: 'gasRanges', label: 'Gas ranges' },
  { key: 'electricRanges', label: 'Electric ranges' },
  { key: 'wokRanges', label: 'Wok ranges' },
  { key: 'Stock pot ranges', label: 'Stock pot ranges' },
  { key: 'Ventilation', label: 'Ventilation' },
]

export const foodProcessorsEquipmentOptions = [
  { key: 'batchBowlProcessors', label: 'Batch bowl processors' },
  { key: 'continuousFeedFoodProcessors', label: 'Continuous feed food processors' },
  { key: 'buffaloChoppers', label: 'Buffalo choppers' },
]

export const mixersEquipmentOptions = [
  { key: 'handMixers', label: 'Hand mixers' },
  { key: 'countertopMixers', label: 'Countertop mixers' },
  { key: 'floorMixers', label: 'Floor mixers (20,30,60,80+ QT)' },
]


export const equipmentOptions1 = [
  { key: 'slicers', label: 'Slicers' },
  { key: 'foodPrepCountersCuttingBoards', label: 'Food prep counters and cutting boards' },
  { key: 'freezersRefrigerators', label: 'Freezers and refrigerators' },
  { key: 'safetyEquipment', label: 'Safety equipment' },
  { key: 'storageRacksShelving', label: 'Storage racks and shelving' },
  { key: 'restaurantKitchenSupplies', label: 'Restaurant kitchen supplies' },
  { key: 'servingWare', label: 'Serving ware' },
  { key: 'storageContainers', label: 'Storage containers' },
]

export const sinksEquipmentOptions = [
  { key: 'compartmentSinks', label: 'Compartment sinks' },
  { key: 'barSinks', label: 'Bar sinks' },
  { key: 'disposalSinks', label: 'Disposal sinks' },
  { key: 'dropInUnits', label: 'Drop-in units' },
  { key: 'handwashingSinks', label: 'Handwashing sinks' },
  { key: 'mopSinks', label: 'Mop sinks' },
  { key: 'portableSinks', label: 'Portable sinks' },
]

export const equipmentOptions2 = [
  { key: 'kitchenDisplaySystem', label: 'Kitchen Display System (KDS)' },
  { key: 'restaurantPointOfSaleSystem', label: 'Restaurant point of sale system' },
  { key: 'steamTables', label: 'Steam tables' },
  { key: 'washingEquipment', label: 'Washing equipment' },
  { key: 'sharpeningStones', label: 'Sharpening stones' },
  { key: 'microwave', label: 'Microwave' },
  { key: 'iceMakers', label: 'Ice makers' },
  { key: 'gasOrElectricGrill', label: 'Gas or electric grill' },
  { key: 'charbroilerGrill', label: 'Charbroiler Grill' },
  { key: 'charcoalGrill', label: 'Charcoal grill' },
  { key: 'tandooriOven', label: 'Tandoori oven' },
]

export const equipmentOptions = [
  ...ovensEquipmentOptions,
  ...rangesAndVentilationEquipmentOptions,
  ...foodProcessorsEquipmentOptions,
  ...mixersEquipmentOptions,
  ...equipmentOptions1,
  ...sinksEquipmentOptions,
  ...equipmentOptions2,
]

export const locationFeaturesOptions = [
  { key: 'parking', label: 'Parking' },
  { key: 'airConditioning', label: 'Air conditioning' },
  { key: 'wifi', label: 'Wifi' },
  { key: 'streetAccess', label: 'Street access' },
  { key: 'curbsidePickup', label: 'Curbside pickup' },
  { key: 'frontOfHouseAccess', label: 'Front of the house access' },
  { key: 'seatingArea', label: 'Seating area' },
]

export const typeOfBusinessOptions = [
  { key: 'restaurant', label: 'Restaurant' },
  { key: 'takeout', label: 'Takeout' },
  { key: 'franchise', label: 'Franchise' },
  { key: 'catering', label: 'Catering' },
  { key: 'coffeeShop', label: 'Coffee shop' },
  { key: 'breakfastLunch', label: 'Breakfast & lunch' },
  { key: 'bakery', label: 'Bakery' },
  { key: 'teaShop', label: 'Tea shop' },
  { key: 'virtualKitchen', label: 'Virtual kitchen' },
]

export const typeOfCuisineOptions = [
  { key: 'International', label: 'International' },
  { key: 'European', label: 'European' },
  { key: 'Asian', label: 'Asian' },
  { key: 'Middle eastern', label: 'Middle eastern' },
  { key: 'Pizza', label: 'Pizza' },
  { key: 'Fast food', label: 'Fast food' },
  { key: 'Seafood', label: 'Seafood' },
  { key: 'Pasta & panini', label: 'Pasta & panini' },
  { key: 'Juice bar', label: 'Juice bar' },
  { key: 'Bakery', label: 'Bakery' },
  { key: 'Pastry', label: 'Pastry' },
]

export const sizeOfDineOptions = [
  { key: '20-40', label: '20-40' },
  { key: '40-60', label: '40-60' },
  { key: '60-80', label: '60-80' },
  { key: '80+', label: '80+' },
]

export const dineEventTypesOptions = [
  { key: 'wedding', label: 'Wedding' },
  { key: 'birthday', label: 'Birthday' },
  { key: 'corpMeeting', label: 'Corporate meeting' },
  { key: 'privateMeeting', label: 'Private meeting' },
  { key: 'specialEvent', label: 'Special event' },
  { key: 'familyGathering', label: 'Family gathering' },
  { key: 'privateParty', label: 'Private party' },
]

export const dineRestrictionsOptions = [
  { key: 'cateringService', label: 'Must have catering service with the event space' },
  { key: 'onlySpace', label: 'Can offer only the space (customer can bring catering from outside)' },
]

export const dineExtrasOptions = [
  { key: 'liquorLicense', label: 'Liquor License' },
  { key: 'outdoorSpace', label: 'Outdoor space' },
]

export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'locationFeatures',
    label: 'Location features',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_locationFeatures'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      // schemaType: 'enum',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: locationFeaturesOptions,
    },
  },
  {
    id: 'commercialEquipment',
    label: 'Commercial Equipment',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_commercialEquipment'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      // schemaType: 'enum',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: equipmentOptions,
    },
    isTwoColumn: true
  },
  {
    id: 'typeOfBusiness',
    label: 'Type of business',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_typeOfBusiness'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      // schemaType: 'enum',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: typeOfBusinessOptions,
    },
  },
  {
    id: 'typeOfCuisine',
    label: 'Type of cuisine',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_typeOfCuisine'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      // schemaType: 'enum',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: typeOfCuisineOptions,
    },
  },
  {
    id: 'listingType',
    label: 'Listing type',
    type: 'SelectSingleFilterCustom',
    group: 'primary',
    queryParamNames: ['pub_listingType'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',
      // schemaType: 'enum',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: listingTypeOptions,
    },
  },
  {
    id: 'sizeOfDine',
    label: 'Size of dine',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_sizeOfDine'],
    config: {
      searchMode: 'has_any',
      options: sizeOfDineOptions,
    },
  },
  {
    id: 'eventTypes',
    label: 'Event types',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_dineEventTypes'],
    config: {
      searchMode: 'has_any',
      options: dineEventTypesOptions,
    },
  },
  {
    id: 'restrictions',
    label: 'Restrictions',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_restrictions'],
    config: {
      searchMode: 'has_any',
      options: dineRestrictionsOptions,
    },
  },
  {
    id: 'extras',
    label: 'Extras',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_extras'],
    config: {
      searchMode: 'has_any',
      options: dineExtrasOptions,
    },
  },
  // {
  //   id: 'certificate',
  //   label: 'Certificate',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_certificate'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
  //       { key: '200h', label: 'Registered yoga teacher 200h' },
  //       { key: '500h', label: 'Registered yoga teacher 500h' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
