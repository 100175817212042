import pick from 'lodash/pick';
import uniqueId from 'lodash/uniqueId';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { apiBaseUrl, transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, nextMonthFn, monthIdStringInTimeZone } from '../../util/dates';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, currentUserShowSuccess, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

import axios from 'axios';
import { Buffer } from 'buffer';
import sha256, { Hash, HMAC } from 'fast-sha256'

const { UUID } = sdkTypes;
const BOOKING_DATE_ID_PREFIX = 'booking_date_';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const BOOKING_DATE_ADD = 'app/ListingPage/BOOKING_DATE_ADD';
export const BOOKING_DATE_REMOVE = 'app/ListingPage/BOOKING_DATE_REMOVE';
export const BOOKING_DATE_EDIT = 'app/ListingPage/BOOKING_DATE_EDIT';
export const BOOKING_LINE_ITEM_ADD = 'app/ListingPage/BOOKING_LINE_ITEM_ADD';

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ListingPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ListingPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_FILES_ERROR = 'app/ListingPage/SAVE_FILES_ERROR';

// ================ Reducer ================ //

const emptyBookingDateId = uniqueId(BOOKING_DATE_ID_PREFIX);
const emptyBookingDate = {
  start: null,
  end: null,
  id: emptyBookingDateId,
};
const emptyBookingLineItems = {
  lineItems: null,
  bookingDateId: emptyBookingDateId,
};

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  bookingDates: [
    emptyBookingDate,
  ],
  bookingLineItems: [
    emptyBookingLineItems,
  ],
  saveFilesError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return fetchLineItemsSuccessCase(state, payload);
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case BOOKING_DATE_ADD:
      return bookingDateAddCase(state);

    case BOOKING_DATE_REMOVE:
      return bookingDateRemoveCase(state, payload);

    case BOOKING_DATE_EDIT:
      return bookingDateEditCase(state, payload);

    case BOOKING_LINE_ITEM_ADD:
      return bookingLineItemAdd(state, payload);

      case SAVE_CONTACT_DETAILS_REQUEST:
        return {
          ...state,
          saveContactDetailsInProgress: true,
          saveFilesError: null,
          contactDetailsChanged: false,
        };
      case SAVE_CONTACT_DETAILS_SUCCESS:
        return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
      case SAVE_FILES_ERROR:
        return { ...state, saveContactDetailsInProgress: false, saveFilesError: payload };

    default:
      return state;
  }
};

const fetchLineItemsSuccessCase = (state, payload) => {
  const bookingDateId = payload.bookingDateId;
  const lineItems = payload.lineItems;
  const newBookingLineItems = state.bookingLineItems.map(item => {
    if (item.bookingDateId === bookingDateId) {
      return {
        ...item,
        lineItems,
      };
    }
    return item;
  });
  return {
    ...state,
    fetchLineItemsInProgress: false,
    bookingLineItems: newBookingLineItems,
  };
};

const bookingDateAddCase = state => {
  const id = uniqueId(BOOKING_DATE_ID_PREFIX);
  return {
    ...state,
    bookingDates: [
      ...state.bookingDates,
      {
        ...emptyBookingDate,
        id,
      },
    ],
    bookingLineItems: [
      ...state.bookingLineItems,
      {
        ...emptyBookingLineItems,
        bookingDateId: id,
      },
    ],
  };
};

const bookingDateRemoveCase = (state, payload) => {
  const bookingDateId = payload;
  return {
    ...state,
    bookingDates: state.bookingDates.filter(item => item.id !== bookingDateId),
    bookingLineItems: state.bookingLineItems.filter(item => item.bookingDateId !== bookingDateId),
  };
};

const bookingDateEditCase = (state, payload) => {
  const bookingDateId = payload.bookingDateId;
  const start = payload.start;
  const end = payload.end;
  const newBookingDates = state.bookingDates.map(item => {
    if (item.id === bookingDateId) {
      return {
        ...item,
        start,
        end,
      };
    }
    return item;
  });
  const newBookingLineItems = state.bookingLineItems.map(item => {
    if (item.bookingDateId === bookingDateId) {
      return {
        ...item,
        lineItems: null,
      };
    }
    return item;
  });
  return {
    ...state,
    bookingDates: newBookingDates,
    bookingLineItems: newBookingLineItems,
  };
};

const bookingLineItemAdd = (state, payload) => {
  const bookingDateId = payload.bookingDateId;
  const lineItems = payload.lineItems;
  const newBookingLineItems = state.bookingLineItems.map(item => {
    if (item.bookingDateId === bookingDateId) {
      return {
        ...item,
        lineItems,
      };
    }
    return item;
  });
  return {
    ...state,
    bookingLineItems: newBookingLineItems,
  };
};

export default listingPageReducer;

// ================ Selectors ====================== //

export const $bookingDates = state => {
  return state.ListingPage.bookingDates;
};

export const $bookingLineItems = state => {
  return state.ListingPage.bookingLineItems;
};

export const $hasBookingLineItems = state => {
  const bookingLineItems = $bookingLineItems(state);
  return bookingLineItems.some(item => !!item.lineItems);
};

// ================ Action creators ================ //

export const bookingDateAddAction = () => ({
  type: BOOKING_DATE_ADD,
});

export const bookingDateRemoveAction = (bookingDateId) => ({
  type: BOOKING_DATE_REMOVE,
  payload: bookingDateId,
});

export const bookingDateEditAction = (bookingDateId, start, end) => ({
  type: BOOKING_DATE_EDIT,
  payload: {
    bookingDateId,
    start,
    end,
  },
});

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = (bookingDateId, lineItems) => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: {
    bookingDateId,
    lineItems,
  },
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveFilesError = error => ({
  type: SAVE_FILES_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const fetchAvailabilityPlan = listingId => async dispatch => {
  const url = new URL(`${apiBaseUrl()}/api/availabilityPlan`);
  const search = new URLSearchParams();
  search.append('l_id', listingId);
  url.search = search.toString();
  const response = await window.fetch(url.toString());
  if (response.ok) {
    const data = await response.json();
    dispatch(addMarketplaceEntities(data));
  } else {
    throw response;
  }
};

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      // dispatch(fetchAvailabilityPlan(listingId.uuid));
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ bookingDateId, bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(bookingDateId, lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};


/**
 * Make a phone number update request to the API and return the current user.
 */
export const saveContactFiles = params => (dispatch, getState, sdk) => {
  const { file, fileId, type, filename } = params;

  return sdk.currentUser
    .updateProfile(
      { protectedData: { [type]: {
        fileId,
        file,
        filename
      }}},
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess());
    })
    .catch(e => {
      dispatch(saveFilesError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/* Delete contact files using the API of FileStack 
https://www.filestackapi.com/api/file/{HANDLE}?key={APIKEY} */

export const deleteContactFiles = params => (dispatch, getState, sdk) => {
  const { fileId, type } = params;

  // Expire the file in 1 hour
  const policy = {
    expiry: Math.round(Date.now() / 1000) + 3600,
    call: ['remove'],
    handle: fileId,
  }

  const base64Policy = new Buffer(JSON.stringify(policy)).toString('base64');

  const hmac = new HMAC(Buffer.from(process.env.REACT_APP_FILESTACK_SECRET_KEY)) // also Hash and HMAC classes
  const mac = hmac.update(Buffer.from(base64Policy)).digest()
  const buf = Buffer.from(Array.from(mac)).toString('hex')  

  return axios.delete(`https://www.filestackapi.com/api/file/${fileId}?key=${process.env.REACT_APP_FILESTACK_API_KEY}&policy=${base64Policy}&signature=${buf}`)
    .then(
      sdk.currentUser
      .updateProfile(
        { protectedData: { [type]: {
          fileId: null,
          file: null,
          filename: null,
        }}},
        {
          expand: true,
          include: ['profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        }
      )
      .then(response => {
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
  
        const currentUser = entities[0];
  
        dispatch(currentUserShowSuccess(currentUser));
        dispatch(saveContactDetailsSuccess());
      })
      .catch(e => {
        dispatch(saveFilesError(storableError(e)));
        // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
        // action will not be fired
        throw e;
      }))
    .catch(e => {
      dispatch(saveFilesError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    })
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]).then(
    responses => {
      if (responses[0] && responses[0].data && responses[0].data.data) {
        const listing = responses[0].data.data;

        // Fetch timeSlots.
        // This can happen parallel to loadData.
        // We are not interested to return them from loadData call.
        fetchMonthlyTimeSlots(dispatch, listing);
      }
      return responses;
    }
  );
};

export const bookingDateAdd = () => dispatch => {
  dispatch(bookingDateAddAction());
};

export const bookingDateRemove = bookingDateId => dispatch => {
  dispatch(bookingDateRemoveAction(bookingDateId));
};

export const bookingDateEdit = (bookingDateId, start, end) => dispatch => {
  dispatch(bookingDateEditAction(bookingDateId, start, end));
};
